@mixin borders($borders) {
  @each $border in $borders {
    $direction: nth($border, 1);
    @if $direction == "all" {
      $size: map-get(map-get($borders, $direction), size);
      $style: map-get(map-get($borders, $direction), style);
      $color: map-get(map-get($borders, $direction), color);
      border: $size $style $color;
    } @else if $direction == "bottom" {
      $size: map-get(map-get($borders, $direction), size);
      $style: map-get(map-get($borders, $direction), style);
      $color: map-get(map-get($borders, $direction), color);
      border-bottom: $size $style $color;
    }
  }
}

@mixin sidebar-width($borders, $width) {
  $sidebar-width: $width;

  @each $border in $borders {
    $direction: nth($border, 1);
    @if $direction == "all" {
      $size: map-get(map-get($borders, $direction), size);
      $sidebar-width: ($sidebar-width - (2 * $size));
    } @else if $direction == "right" {
      $size: map-get(map-get($borders, $direction), size);
      $sidebar-width: $sidebar-width - $size;
    } @else if $direction == "left" {
      $size: map-get(map-get($borders, $direction), size);
      $sidebar-width: $sidebar-width - $size;
    }
    width: $sidebar-width;
  }
}

@mixin hover-focus() {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin plain-hover-focus() {
  &,
  &:hover,
  &:focus {
    @content;
  }
}
@mixin card-accent-variant($color) {
  border-top-width: 2px;
  border-top-color: $color;
}

@mixin card-accent-left-variant($color) {
  border-left: 2px solid $color;
}

@mixin xs {
	@media (max-width: 479px) {
		@content;
	}
}
@mixin sm {
	@media (max-width: 599px) {
		@content;
	}
}
@mixin md {
	@media (max-width: 767px) {
		@content;
	}
}
@mixin lg {
	@media (max-width: 959px) {
		@content;
	}
}
@mixin xl {
	@media (max-width: 1023px) {
		@content;
	}
}
@mixin xxl {
	@media (max-width: 1279px) {
		@content;
	}
}
@mixin rwd($screen) {
	@media (min-width: $screen+"px") {
		@content;
	}
}
@mixin rwd-max($screen) {
	@media (max-width: $screen+"px") {
		@content;
	}
}