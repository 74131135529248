body {
  font-family: "Montserrat", sans-serif;
  &.modal-popup-open {
    overflow: hidden;
    position: fixed;
    width: 100%;
  }
}
.theme-container {
  max-width: 1550px;
  margin: 0 auto;
  padding: 0 16px;
}
.cursor-pointer {
  cursor: pointer;
}
.w-95 {
  width: 95%;
}
small {
  font-size: 80%;
}

.font-sm {
  font-size: 0.85rem !important;
}

.font-xs {
  font-size: 0.75rem !important;
}
.font-lg {
  font-size: 1.125rem;
}
.font-2xl {
  font-size: 2rem;
}
.font-3xl {
  font-size: 3rem !important;
}
.fw-bold {
  font-weight: 700;
}
.fw-300 {
  font-weight: 300;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.radius-0 {
  border-radius: 0 !important;
}

.icon-primary {
  color: $blue;
}
.btn-light {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
  &:hover {
    background-color: #e3e3e3;
    border-color: transparent;
  }
}
.text-link {
  color: $blue;
  text-decoration: underline;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
}
.form-custom {
  &.is-invalid {
    border-color: #dc3545;
  }
  &
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(
      .invalid-feedback
    ) {
    margin-left: inherit;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
}
.card {
  border-radius: 0 !important;
  border-color: #e3e3e3;
  .card-header {
    border-radius: 0 !important;
  }
}

.bg-grey {
  background-color: #f5f6fa;
}

.card-penncp-custom {
  border: 0;
  .card-header {
    border: 0;
    border-radius: 0;
    background-color: #e6eaf4;
    color: #383838;
    span,
    a {
      font-size: 13px;
      @include sm {
        font-size: 12px;
        display: block;
      }
    }
  }
}
.table-light {
  background-color: #f1f2f6;
  th {
    background-color: #f1f2f6;
    font-size: 13px;
    color: #444444;
  }
}

.calendar-wrap {
  position: relative;
  font-weight: 400;
  font-size: 13px;
  .form-control {
    padding-left: 35px;
    font-weight: 400;
    font-size: 13px;
    padding-top: 9px;
    padding-bottom: 9px;
  }
  .bi-calendar {
    position: absolute;
    left: 14px;
    top: 10px;
  }
  .bi-x-lg {
    position: absolute;
    right: 14px;
    top: 10px;
  }
}
.wid-75 {
  width: 75px;
  @include sm {
    width: 100%;
    .btn {
      width: 100%;
    }
  }
}

.modal {
  .modal-dialog {
    .modal-content {
      border-radius: 0;
      .modal-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      .modal-body {
        padding: 24px;
      }
    }
  }
}

.toggle-button {
  position: absolute;
  right: 20px;
  z-index: 1;
}
.cdk-overlay-backdrop {
  z-index: 1000 !important;
}
.disabled {
  background-color: #e2e2e2;
  opacity: 1;
}
.min-h-100 {
  min-height: 100px;
}
.button-wrap {
  padding: 10px;
}

.sk-child {
  background-color: $blue;
}
.logo-nav {
  padding: 0;
  list-style-type: none;
  padding-bottom: 10px;
  li {
    display: inline-block;
    padding-right: 2px;
  }
  .h6 {
    font-size: 12px;
  }
}
.font-weight-bold {
  font-weight: 600;
}
.stitch-table {
  a {
    color: #4da9e6;
  }
  .color {
    span {
      width: 20px;
      height: 20px;
    }
  }
  thead th {
    background-color: #eff4fa;
  }
}

.ng-placeholder {
  color: #61677a !important;
}

.accordion {
  .accordion-button {
    &::after {
      --bs-accordion-btn-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
      --bs-accordion-btn-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    }
  }
}
.ng-select .ng-select-container {
  color: $gray-900 !important;
}
.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #e2e2e2 !important;
  color: #61677a !important;
}

.pl-2 {
  padding-left: 20px;
}

.btn-go {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 1.536;
}

.table-search-wrap {
  .search-icon {
    top: 6px !important;
  }
  .form-control {
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 1.536;
  }
}

.btn {
  $btn-font-weight: $font-weight-bold;
  &.btn-custom-width {
    max-width: 250px;
  }
}
.filter-table-wrap {
  .btn {
    &.btn-primary {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }
}
.w-18 {
  width: 18px;
}

.form-control {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #c4c4c4;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #c4c4c4;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #c4c4c4;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: #c4c4c4;
  }
}
.img-swatch {
  width: 18px;
}
.line-item-wrap{
  .line-item{
    flex: 0 48%;
    &:first-child{
      font-size: 13px;
    }
  }
}
.line-left-side{
  border-left: 5px solid #C4C4C4;
  height: 100%;
  padding-left: 6px;
}
.middle-inner{
  margin-bottom: 10px;
  .btn-download{
   font-size: 12px;
  }
}


.addresslist-body{
  .addressslist-wrap-scroll{
    height: 58dvh;
    overflow-y: auto;
  }
}

.btn-cancel{
    font-size: 14px;
    padding-left: 3rem;
    padding-right: 3rem;
    max-width: 120px;
    display: flex;
    justify-content: center;
}